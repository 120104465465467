@import '../vars/vars.scss';

.parn {
    background-color: var(--purple200);
    position: relative;
    overflow: hidden;
    padding-bottom: 6.6em;
    z-index: 1;

    &:after {
        background: url($imgPath + '48df1ad6-aa6b-4ff2-9e23-9b8482172eb7.svg') repeat;
        background-size: 80em auto;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: .9;
    }

    @include above(map-get($breakpoints, tablet-wide)) {
        margin-top: -6.3em;
        padding-bottom: 2em;
    }

    @include above(map-get($breakpoints, desktop)) {
        margin-top: -8.6em;
    }

    &-img {
        margin: 4em auto 0;
        width: 26em;
        transform: translateX(-1em);

        @include above(map-get($breakpoints, tablet-wide)) {
            margin: 3em auto 0;
            width: 50em;
            transform: none;
        }
        @include above(map-get($breakpoints, desktop)) {
            margin: 4em auto 0;
            width: 46em;
            transform: translateX(5em)
        }

        img {
            width: 100%;
        }
    }

    &-tble {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        max-width: 23.5em;
        margin: 0 auto;

        @include above(map-get($breakpoints, tablet-wide)) {
            flex-direction: row;
            max-width: 56.5em;
            align-items: center;
        }

        @include above(map-get($breakpoints, desktop)) {
            max-width: 70em;
        }
        
    }

    &-info {
        padding-top: 2.5em;
        padding-left: 2em;
        position: relative;
        z-index: map-get($layers, secondFloor);

        @include above(map-get($breakpoints, tablet-wide)) {
            padding: 2.3em 0 0 2em;
        }
        @include above(map-get($breakpoints, desktop)) {
            width: 30em;
            padding: 1em 0 0 1.6em;
        }

        &-text {
            margin-top: 2.2em;
        }

        .dny {
            &-txt {
                font-size: 1.3em;
                color: var(--white);

                @include above(map-get($breakpoints, desktop)) {
                    font-size: 1.72em;
                }
            }
        }
    }

    &-nav {
        padding-top: 2em;
    }
}