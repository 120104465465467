@import '../../vars/vars.scss';

.tabs {
    overflow-y: hidden;
    box-shadow: none;

    &-nav {
        display: block;
        position: relative;
        max-width: 22em;
        margin: 0 auto;
        width: 100%;
        position: relative;
        background-color: var(--green600);
        overflow: hidden;
        border-radius: 4em;

        @include above(map-get($breakpoints, tablet-wide)) {
            max-width: 23em;
        }

        @include above(map-get($breakpoints, desktop)) {
            max-width: 26em;
            font-size: 1.2em;
        }

        &::before {
            content: "";
            position: absolute;
            width: 0;
            height: 100%;
            background-color: var(--white);
            box-shadow: 0 0 1em rgba(0,0,0,.4);
            border-radius: 4em;
            transition: .50s cubic-bezier(0.445, 0.05, 0.55, 0.95);
        }

        &--left {
            &::before {
                width: calc(50.8%);
            }
            .dny-btn--tab-active {
                padding-left: 1em;
            }
        }
        &--right {
            &::before {
                width: calc(55%);
                right: 0;
            }
            .dny-btn--tab-active {
                padding-right: 1.4em;
            }
        }

        &-list {
            list-style-type: none;
            padding: 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &-item {
                width: 50%;

                &--active {
                    z-index: 2;
                    width: 65%;
                }
            }
        }

        .dny {
            &-btn {
                max-width: 100%;
                width: 100%;
            }
        }
    }

    &-slider {
        height: 4px;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        margin-left: 2px;
        margin-right: 2px;
        bottom: 0;
        position: absolute;
        background: #08e;
    }

    .dny-subsubtitle {
        font-family: var(--title-font);
        font-weight: 400;
        font-style: italic;
        position: relative;
        font-size: 1.25em;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        &-data {
            width: calc(100% - 40px);
            display: block; 
            margin-left: 40px;

            @include above(map-get($breakpoints, tablet-wide)) { 
                width: 100%;
                margin-left: 0;
                font-size: 1.15em;
                max-width: 16em;;
            }
            @include above(map-get($breakpoints, desktop)) { 
                font-size: 1.55em;
            }
        }

        &:after{
            width: 2px;
            content: '';
            position: absolute;
            height: 85%;
            top: 1.6em;
            left: 50%;
            margin-left: -8em;
            border-right: 2px dashed;

            @include above(map-get($breakpoints, phone-m)) { 
                margin-left: -8.1em;
            }

            @include above(map-get($breakpoints, tablet-wide)) { 
                margin-left: -8.75em
            }
            @include above(map-get($breakpoints, desktop)) { 
                margin-left: -14.15em;
                border-width: 3px;
                top: 1.9em;
            }
        }
        .tab-list {
            &-bullet {
                background: transparent;
                border: 2px solid var(--white);

                &-container {
                    padding-top: .8em;

                    @include above(map-get($breakpoints, tablet-wide)) {
                        padding-left: .5em;
                    }
                }
            }
            &-icon {
                height: inherit;
            }
        }
    }
}