[data-theme="root"] {
    // Basic green
    --green100: #39af4c;
    --green200: #5BC66E;
    --green300: #8ADC98;
    --green400: #1A9F31;
    --green500: #0C7C20;
    --green600: #C3E2C6;
  
    // Reds
    --red100: #f24949;
    --red200: #FF6F6F;
    --red300: #FF9A9A;
    --red400: #E31E1E;
    --red500: #AE0D0D;

    // Custom Colors
    --purple100: #7349f7;
    --purple200: #3c3177;

    --mustard100: #F7B541;

    --white: #ffff;
    --dark: #595959;
    --grey: #f5f5f5;
    --markgrey: #A4A4A4;
    --black: #0a0b09;
  }
  