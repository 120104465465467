@import '../vars/vars.scss';
@import '../components/UI/registration-hub.scss';
@import '../components/UI/registration.scss';


.form {
    &-registro {
        background-image: url($imgPath + 'img-top-registration.jpg');
        background-position: -7.9em 0em;
        background-repeat: no-repeat;
        background-size: 60em auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        font-family: var(--title-font);

        @include above(map-get($breakpoints, tablet-wide)) {
            background-image: url($imgPath + 'img-top-registration-destk.jpg');
            background-position: center top;
            background-size: cover;
            flex-direction: row;
            align-items: center;
        }
    }
    &-wrapper {
        display: flex;
        flex-direction: column; 
        justify-content: space-between;
        width: 100%;
        height: 100%;
        color: var(--white);

        @include below(map-get($breakpoints, tablet-wide)) {
            min-height: 75em;
        }

        @include above(map-get($breakpoints, tablet-wide)) {
            width: 100%;
            max-width: 62em;
            padding-top: 12em;
            height: 100%;
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
            padding-bottom: 5.8em;
        }

        @include above(map-get($breakpoints, desktop)) {
            max-width: 80.5em;
            padding-top: 15.5em;
        }
    }
}