@import '../../vars/vars.scss';

.accordion {
    &-tab {
        border-width: 0 0 1.5px 0;
        border-style: solid;
        border-color: var(--green100);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 0.8em;
        padding-bottom: 0.8em;

        @include above(map-get($breakpoints, desktop)) {
            font-size: 1.35em;
        }
    }

    &-question {
        font-family: var(--title-font);
        font-weight: 600;
        font-size: 1.25em;
        font-style: italic;
        max-width: 14em;
        width: 100%;
        color: var(--dark);

        &-icon {
            display: block;
            width: 1em;
            height: 1em;
            cursor: pointer;
            color: var(--green100)
        }

        @include above(map-get($breakpoints, tablet-wide)) {
            font-size: 1.35em;
            max-width: 24em;
        }
    }
    &-answer {
        padding: 1em;
        color: var(--dark);
    }
}