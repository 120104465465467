@import '../vars/vars.scss';

.onp {
    &-home {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;
        padding-top: 13em;
        padding-left: 2em;

        &:after {
            background: url($imgPath + 'ceacaff2-d867-400c-88f9-a2f109637101.svg') repeat;
            background-size: 80em auto;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            opacity: .5;
        }  

        @include below(360px) {
            min-height: 47em;
        }

        @include above(360px) {
            min-height: 47em;
        }
        
        @include above(map-get($breakpoints, tablet-wide)) {
            min-height: 45.5em;
            padding-top: 15.3em;
        }

        @include above(map-get($breakpoints, desktop)) {
            min-height: 64em;
            padding-top: 20.3em;
        }

        .dny-title {
            color: var(--purple200);
            line-height: 0.82em;

            @include above(map-get($breakpoints, tablet-wide)) {
                font-size: 5.6em;
            }

            @include above(map-get($breakpoints, desktop)) {
                font-size: 7.5em;
            }
        }
    }
}

.home {
    background-color: var(--mustard100);

    &-info {
        position: relative;
        z-index: map-get($layers, firstFloor );

        @include above(map-get($breakpoints, phone-wide)) {
            min-width: 23.5em;
            margin: 0 auto;
        }

        @include above(map-get($breakpoints, tablet-wide)) {
            min-width: 62.5em;
        }

        &-text {
            max-width: 19.5em;
            margin-top: 3.3em;
            margin-bottom: 1.5em;

            @include above(map-get($breakpoints, phone-wide)) {
                min-width: 23.5em;
                margin-top: 1.6em;
            }

            @include above(map-get($breakpoints, tablet-wide)) {
                min-width: 21.8em;
            }

            @include above(map-get($breakpoints, desktop)) {
                max-width: 30em;
                margin-top: 1.2em;
                margin-bottom: 0.5em;
            }
        }

        @include above(map-get($breakpoints, desktop)) {
            max-width: 78em;
            margin: 0 auto;
            width: 100%;
        }
    }
    .dny {
        &-txt {
            font-size: 1.11em;
            padding-bottom: 2em;
            color: #000;
            line-height: 1.5em;

            @include above(map-get($breakpoints, tablet-wide)) {
                font-size: 1.054em;
            }

            @include above(map-get($breakpoints, desktop)) {
                font-size: 1.54em;
                padding-bottom: 1.8em;
            }
        }

        &-title {
            display: block;
        }
    }
}


