@import '../../vars/vars.scss';

.tmp {
    &--loader {
        background: var(--green100);
        position: fixed;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        z-index: map-get($map: $layers, $key: outaSpace);

        &::after {
            background: url($imgPath + '48df1ad6-aa6b-4ff2-9e23-9b8482172eb7.svg') repeat;
            background-size: 80em auto;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
        }  
    }
}

.loader {
    &-dino {
        overflow: hidden;
        max-width: 18em;
        height: 15em;
        position: relative;
        width: 100%;
        z-index: 1;

        &--horizontal {
            max-width: 23em;
            height: 6em;;
        }

        &-wrapper {
            position: absolute;
            width: 16.4em;
            left: -0.2em;
        }

        &-square {
            background-color: var(--red100);
            width: 14.4em;
            height: 15em;
            position: absolute;
            border-radius: 2em;
            z-index: -1;
            left: 1.5em;

            &--partial {
                font-size: .4em;
            }
        }

        &-txt {
            position: absolute;
            width: 14em;
            right: 0.5em;
            top: 1.1em;
            z-index: -2;
        }
    }
    &-logo {
        opacity: 0;
        width: 12em;
        height: 12em;
        position: relative;
        margin: 0 auto;
        z-index: map-get($layers, firstFloor);

        &:before {
            content: '';
            position: absolute;
            left: -2em;
            top: -2em;
            right: -2em;
            bottom: -2em;
            z-index: -1;
            border-radius: 12em;
        }

        &::after {
            content: '';
            width: 12em;
            height: 12em;
            position: absolute;
            left: -.5em;
            top: 0%;
        }

        &-caption {
            font-weight: 600;
            font-size: 2.5em;
            color: var(--white);
            margin-top: 6em;
            text-align: center;
        }

        img {
            transform: translateX(-0.25em);
        }
    }
}
.st0{fill:#5ABB6E;}
.st1{fill:#C3E2C6;}
.st2{fill:#39AF4C;}
.st3{fill:#F7B541;}
.st4{fill:#FFDEA4;}
.st5{fill:#FFFFFF;}
  
