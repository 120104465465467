.regis{
    &-form{
        width: 21.5em;
        margin: auto;
        border-radius: 1em;
        background-color: var(--white);
        min-height: 75vh;
        z-index: map-get($layers, firstFloor);
        padding: 2.5em 1em;
        overflow: hidden;

        @include above(map-get($breakpoints, tablet)) {
            width: 24em;
        }

        @include above(map-get($breakpoints, tablet-wide)) {
            width: 48.5em;
            margin: inherit;
            margin-bottom: inherit;
            height: 100%;
            min-height: 40em;
            margin-right: 1em;
        }
        @include above(map-get($breakpoints, desktop)) {
            max-width: 28em;
            margin-right: inherit;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
        @media screen and (min-width: map-get($breakpoints, desktop )) and (max-height: 960px) and (max-width: map-get($breakpoints, desktop-wide )) {
            max-width: 32em;
        }
        .hs-input{
            border: 0!important;
            appearance: none!important;
            border-radius: 0!important;
            background:#fff!important;
            border-bottom: 1px solid red!important;
        }
        .hs-form-iframe {
            @include above(map-get($breakpoints, tablet-wide)) {
                width: 25em !important;
            }
            @include above(map-get($breakpoints, desktop)) {
                width: 24em !important;
            }
            @media screen and (min-width: map-get($breakpoints, desktop )) and (max-height: 960px) and (max-width: map-get($breakpoints, desktop-wide )) {
                width: 28em !important;
            }
        }

    }
    &-info{
        max-width: 17em;
        color: var(--white);
        padding-left: 2em;
        padding-top: 6em;
        min-height: calc(95.4vh - 10em);

        @include above(map-get($breakpoints, tablet)) {
            max-width: 22em;
            color: var(--white);
            padding-left: 2em;
            padding-top: 6em;
            min-height: 50vh;
            width: 100%;
        }

        @include above(map-get($breakpoints, tablet-wide)){
            padding-top: 1.3em;
            max-width: 58em;
            padding-top: 0.6em;
            min-height: inherit;
            height: inherit;
            padding-left: 2.5em;
        }

        @media screen and (min-width: map-get($breakpoints, desktop )) and (max-height: 960px) and (max-width: map-get($breakpoints, desktop-wide )) {
            width: 28em !important;
        }

        &-presentation {
            font-family: var(--title-font);
            font-weight: 600;
            font-stretch: normal;
            font-style: italic;
            line-height: 1;
            letter-spacing: normal;
            font-size: 1.25em;

            @include above(map-get($breakpoints, tablet-wide)) {
                font-size: 1.55em;
            }

            @include above(map-get($breakpoints, desktop)) {
                font-size: 2em;
                letter-spacing: 0;
            }
        }
        &-description {
            max-width: 6.6em;
            font-family: var(--title-font);
            font-weight: 600;
            font-stretch: normal;
            font-style: italic;
            line-height: 1.1em;
            font-size: 1.8em;

            @include above(map-get($breakpoints, tablet-wide)) {
                font-size: 2.4em;
                max-width: 8.5em;
                line-height: 1em;
            }
            @include above(map-get($breakpoints, desktop)) {
                font-size: 3.15em;
                min-height: inherit;
            }
        }
    }
}
