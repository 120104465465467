//- Config
@import './vars/vars.scss';
@import './themes/theme.scss';

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
}

.dny {
  &-logo {
    display: block;
    width: 7.5em;
  }

  // Titles
  &-title {
    font-family: var(--title-font);

    &--big {
      font-weight: 800;
      font-style: italic;
      font-size: 4.2em;
    }
  }

  &-subtitle {
    font-family: var(--title-font);
    font-size: 1.8em;
    font-style: italic;

    @include above(map-get($breakpoints, desktop)) {
      font-size: 2.55em;
    }

    &-small {
      display: block;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 0.75em;
    }

    &--white {
      color: var(--white);
    }
    &--dark {
      color: var(--dark);
    }
  }

  // Text
  &-txt {
    font-family: var(--txt-font);
    color: var(--dark);

    &--highlight {
      font-family: var(--title-font);
      font-weight: 700;
      font-style: italic;
    }

    &--italic {
      font-style: italic;
    }
  }
}

.tmp--onepage {
  overflow: hidden;
}
// Aux paddings
.pb-0 {
  padding-bottom: 0!important;
}
