@import '../../vars/vars.scss';

.benefitcard { 
    position: relative;
    padding: .4em;
    color: #595959;

    @include above(map-get($breakpoints, tablet-wide)) {
        width: 100%;
    }

    figure {
        position: relative;
        max-width: 16em;
        width: 100%;
        margin: 0 auto;
        transform: scale(1.8);

        @include above(map-get($breakpoints, tablet-wide)) {
           max-width: 12em;
        }

        img {
            width: 100%
        }
    }

    &-description {
        padding: 1em;  
        //font-weight: bold;
        font-style: italic;
        font-size: 1.2em;
        text-align: center;  
    }

    &-info {
        padding-top: 2em;
        max-width: 16em;
        width: 100%;
        margin: 0 auto;

        @include above(map-get($breakpoints, tablet-wide)) {
            padding-top: 3em;
         }        
    }
}