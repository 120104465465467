@import '../../vars/vars.scss';

.dny {
    &-footer {
        &-sign {
            text-align: center;
            margin-bottom: 3.5em;

            @include above(map-get($breakpoints, desktop)) {
                margin-bottom: 5em;
            }
            
            .dny {
                &-txt {
                    font-family: var(--title-font);
                    font-style: italic;
                    font-size: 1.25em;
                    max-width: 13.5em;
                    margin: 0 auto;

                    @include above(map-get($breakpoints, tablet-wide)) {
                        max-width: 100%;
                        font-size: 1em;
                    }   

                    @include above(map-get($breakpoints, desktop)) {
                        font-size: 1.3em;
                    }
            
                    &--strong {
                        font-weight: 700;
                    }
                }
            }
        }
        .dny {
            &-logo {
                &--ver {
                    max-width: 6.8em;
                    margin: auto;

                    @include above(map-get($breakpoints, tablet-wide)) {
                        margin: 1em 0 0 2.5em;
                    }

                    @include above(map-get($breakpoints, desktop)) {
                        margin: 1em 0 0;
                        max-width: 9.8em;
                        width: 100%;
                    }

                    img {
                        width: 100%;
                    }
                }
            }
        }
    }
}