@import '../vars/vars.scss';

.buss {
    background: url($imgPath + '48df1ad6-aa6b-4ff2-9e23-9b8482172eb7.svg') repeat;
    background-size: 80em auto;
    background-color: var(--green100);
    overflow: hidden;
    position: relative;
    min-height: 67.2em;
    

    @include above(map-get($breakpoints, tablet-wide)) {
        min-height: 52em;
        height: 100%;
    }

    @include above(map-get($breakpoints, desktop)) {
        min-height: 66em;
    }
    
    &-info {
        padding-top: 3em;

        @include above(map-get($breakpoints, desktop)) {
            padding-top: 4em;
        }
    }

    .dny {
        &-subtitle {
            text-align: center;
            max-width: 10em;
            margin: 0 auto;

            @include above(map-get($breakpoints, tablet-wide)) {
                max-width: 24em;
                font-size: 2em;
            }

            @include above(map-get($breakpoints, desktop)) {
                max-width: 24em;
                font-size: 2.6em;
            }

            &-small {
                padding-bottom: .5em;

                @include above(map-get($breakpoints, tablet-wide)) {
                    font-size: .6em;
                    padding-bottom: .1em;
                }

                @include above(map-get($breakpoints, desktop)) {
                    padding-bottom: 0.4em;
                    font-size: 0.6em;
                }
            }
        }
    }
    &-tble {
        padding-top: 3em;

        @include above(map-get($breakpoints, tablet-wide)) {
            padding-top: 4em;
        }

        @include above(map-get($breakpoints, desktop)) {
            padding-top: 5em;
        }
    }
}
