@import '../../vars/vars.scss';

.dny {
    &-nav {
        &-mobilewrapper {
            position: fixed;
            top: 0;
            right: 0;
            width: 100%;
            height: 100%;
            transition: .30s cubic-bezier(0.39, 0.575, 0.565, 1);
            pointer-events: none;
            opacity: 0;

            &--open {
                pointer-events: inherit;
                opacity: 1;
            }

            header {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                padding: 1em;
                position: relative;
                z-index: map-get($layers, firstFloor );
            }
        }

        &-list {
            list-style-type: none;
            padding: 0;
            margin-top: 6em;

            @include above(map-get($breakpoints, tablet-wide)) {
                margin: 0;
                display: flex;
                flex-direction: row;
            }
        }

        &-button {
            font-size: 1.6em;
            width: 1em;
            height: 1em; 
            position: relative;
            z-index: map-get($layers, thirdFloor);

            &--open {
                path {
                    stroke: var(--green400);
                }
            }
        }

        &-item {
            text-align: center;
            margin-bottom: 2em;

            @include above(map-get($breakpoints, tablet-wide)) {
                padding: 0 1em;
                margin: 0;
            }

            span {
                position: relative;
                padding-bottom: 0.2em;
                font-family: var(--title-font);
                font-style: italic;
                font-weight: 700;
                display: inline-block;
                font-size: 1.5em;
                cursor: pointer;

                @include above(map-get($breakpoints, tablet-wide)) {
                    font-size: 1em;
                }

                @include above(map-get($breakpoints, desktop)) {
                   font-size: 1.2em;
               } 
                
                &::after {
                    position: absolute;
                    opacity: 0;
                    content: '';
                    bottom: .2em;
                    width: 100%;
                    background-color: var(--green100);
                    height: 2px;
                    left: 0;
                    border-radius: 10px;
                    transition: .30s linear;
                }
            }

            &--current {
                span {
                    &::after {
                        opacity: 1;
                        bottom: 0;
                    }
                }
            }
        }
    }

    &-header--down {
        .dny {
            &-nav-button {
                path {
                    stroke: var(--green400);
                }
            }
        }
    }
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    background: var(--white);
}