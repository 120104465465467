.dny {
    &-page {
        position: relative;

        &-fluid {
            // position: absolute;
            width: 100%;
        }
    }
}


