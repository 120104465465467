@import '../../vars/vars.scss';

.card {
    background-color: var(--white);
    padding: .4em;
    border-radius: 1em;
    box-shadow: 0 0.5em 0.3em rgba(0,0,0,.1);
    margin: 0 auto;
    max-width: 18em;
    width: 18em;
    position: relative;

    @include above(map-get($breakpoints, tablet-wide)) {
        max-width: 24em;
        width: 100%;
    }

    @include above(map-get($breakpoints, desktop)) {
        max-width: 31.5em;
        // width: 31.5em;    
    }

    &-img {
        img {
            width: 100%;
        }
    }

    &-info {
        padding-top: 1em;
        padding-left: .2em;
        padding-bottom: .8em;

        @include above(map-get($breakpoints, desktop)) {
            padding-top: 1.5em;
            padding-bottom: 1.8em;
        }
    }
    .dny-subsubtitle {
        font-size: 1.3em;

        @include above(map-get($breakpoints, desktop)) {
            font-size: 2em;
        }
    }
    &-category {
        span {
            display: inline-block;
            margin-right: .4em;

            &:nth-child(2){
                margin-left: .2em;
            }

            @include above(map-get($breakpoints, desktop)) {
                font-size: 1.5em;
                padding-top: 0.3em;
            }
        }
    }
}