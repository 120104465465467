@import '../vars/vars.scss';

.prosdny{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    width: 100%;
    font-family: var(--title-font);
    background: url($imgPath + 'fd867f35-22cd-496d-813a-5fbd463c641b.svg') repeat;
    background-repeat: repeat;
    background-size: 80em auto;

    &-wrapper{
        width: 100%;
        padding: 5em 0;
        @include above(map-get($breakpoints, tablet-wide)) {
            padding: 4.6em 0;
            max-width: 78em;
        }

        @include above(map-get($breakpoints, desktop)) {
            padding: 6.6em 0;
        }
    }

    &-info{
        text-align: center;
        color: var(--dark);
        font-style: italic;
        max-width: 18em;
        margin: auto;

        @include above(map-get($breakpoints, tablet-wide)) {
            width: 100%;
            max-width: 48em;
        }

        .title{
            &-txt{
                text-transform: uppercase;
                font-size: 1.3em;
                font-weight: 400;
                padding-bottom: .3em;
            }
            &-main{
                font-weight: bold;
                font-size: 1.8em;
                padding-bottom: 0.7em;
                max-width: 9em;
                margin: auto; 
                @include above(map-get($breakpoints, tablet-wide)) {
                    max-width: inherit;
                }

                @include above(map-get($breakpoints, desktop)) {
                    font-size: 2.6em;
                }
            }
            &-description{
                font-family: Roboto;
                font-size: 1.2em;
            }
        }
    }
    &-benefit{
        width: 100%;
        padding-top: 3.5em;

        @include below(map-get($breakpoints, tablet)) {
            margin: 0 auto;
            max-width: 24em;
        }

        &-desktop {
            @include above(map-get($breakpoints, tablet-wide)) {
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                justify-content: space-between;
                max-width: 52em;
                margin: 0 auto;
                padding-top: 2em;
            }

            @include above(map-get($breakpoints, desktop)) {
                max-width: 68em;
                padding-top: 5em;
            }
        }
    }
    .swiper-wrapper {
        padding: 2em 0 ;
    }
    .swiper-slide {
        max-width: 24em;
    }

}