@import '../vars/vars.scss';

.solution {
    background-color: var(--green100);
    position: relative;
    min-height: 42em;
    padding-top: 4em;

    @include above(map-get($breakpoints, tablet-wide)) {
        min-height: 49.2em;
        padding-top: 4.8em;
    }

    @include above(map-get($breakpoints, desktop)) {
        min-height: 66.1em;
        padding-top: 6.4em;
    }

    &-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: map-get($layers, firstFloor);

        &-button {
            background: url($imgPath + '44311df2-2831-4b8e-b98a-ab779588d660.jpeg') no-repeat right top;
            background-size: cover;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            @include above(map-get($breakpoints, desktop)) {
                background-position: center 50%;
            }

            &--icon {
                width: 3.5em;
                height: 3.5em;

                @include above(map-get($breakpoints, desktop)) {
                    width: 4.5em;
                    height: 4.5em;
                }

                svg {
                    width: 100%;
                    height: 100%;
                    color: var(--white)
                }
            }
        }
    }
    
    .dny {
        &-subtitle {
            text-align: center;
            max-width: 11em;
            margin: 0 auto;
            position: relative;
            z-index: map-get($layers, secondFloor);

            @include above(map-get($breakpoints, tablet-wide)) {
                max-width: 20em;
                font-size: 1.95em;
            }

            @include above(map-get($breakpoints, desktop)) {
                font-size: 2.58em;
                line-height: 1.05em;
            }

            &-small {
                margin-bottom: .6em;

                @include above(map-get($breakpoints, tablet-wide)) {
                    font-size: .7em;
                    margin-bottom: 0;
                }

                @include above(map-get($breakpoints, desktop)) {
                    font-size: .65em;
                    margin-bottom: 0;
                }
            }
        }
    }

    &-video {
        position: absolute;
        width: 100%;
        height: 100%;

        &-anim {
            position: absolute;
            height: 100%;
            width: 100%;
        }

        &--close {
            position: absolute;
            z-index: 1;
            font-size: 2em;
            right: 1em;
            top: 1em;
            cursor: pointer;

            path {
                stroke: var(--green400);
            }
        }
    }
}