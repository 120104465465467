@import '../../vars/vars.scss';

.dny {
    &-header {
        position: absolute;
        padding: 0.8em 1.5em;
        width: 100%;
        left: 0;
        top: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        z-index: map-get($layers, outaSpace );
        transition: .30s cubic-bezier(0.39, 0.575, 0.565, 1);

        @include above(map-get($breakpoints, phone-wide)) {
            justify-content: space-between;
        }
        
        @include above(map-get($breakpoints, tablet-wide)) {
            padding: 1em 1em;
        }

        @include above(map-get($breakpoints, desktop)) {
            padding: 2.5em 1em;
        }

        &--global {
            background-color: var(--white);
        }

        &--down {
            background-color: var(--white);
            position: fixed;
            box-shadow: 0em 0.2em 0.8em rgba(0,0,0,.3);
            border-radius: 0 0 0.6em 0.6em;

            .dny {
                &-logo {
                    opacity: 0 !important;

                    &--hor {
                        opacity: 1;
                    }
                }
            }
        }

        &--global {
            .dny {
                &-logo--hor {
                    opacity: 1;
                }
            }
        }

        &-panel {
            @include above(map-get($breakpoints, phone-wide)) {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                align-items: center;
            }
            @include above(map-get($breakpoints, tablet-wide)) {
                max-width: 84em;
                margin: 0 auto;
                position: relative;
            }  
        }
    }

    &-logo {
        display: block;
        width: 7.5em;
        position: absolute;
        top: 3.3em;
        opacity: 1;
        left: 2.5em;

        @include above(map-get($breakpoints, desktop)) {
            font-size: 1.6em;
            left: 3em;
        }

        &--hor {
            display: block;
            opacity: 0;
            width: 7.6em;
            position: absolute;
            top: 0.5em;
            left: 2.5em;
            transition-delay: .20s;
            transition: 1s cubic-bezier(0.39, 0.575, 0.565, 1);

            @include above(map-get($breakpoints, tablet-wide)) {
                top: -0.4em;
            }

            @include above(map-get($breakpoints, desktop)) {
                font-size: 1.2em;
                top: -0.2em;
                left: 3.5em;
            }
        }
    }
}

.header {
    &-pnl {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @include above(map-get($breakpoints, tablet-wide)) {
            max-width: 95.5%;
            margin: 0 auto;
        }
    }
}