@import '../../vars/vars.scss';

.tab {
    &-image {
        margin: 0 auto;
        max-width: 20em;

        @include below(map-get($breakpoints, tablet)) {
            margin: 2em auto 0
        }

        @include above(map-get($breakpoints, tablet-wide)) {
            margin: 0;
            max-width: 31em;
            position: absolute;
            right: -3.9em;
            top: 50%;
            margin-top: -11.7em;
        }

        @include above(map-get($breakpoints, desktop)) {
            margin: -15.3em -.6em 0;
            max-width: 41.5em;   
        }

        img {
            width: 100%;
        }

        &--comensal {
            @include above(map-get($breakpoints, tablet-wide)) {
                margin-top: -17.4em;
                margin-right: 0.9em;
                max-width: 26em;
            }
            @include above(map-get($breakpoints, desktop)) {
                margin-top: -21em;
                margin-right: 0em;
                max-width: 34em;
            }
        }
    }
    &-list {
        list-style-type: none;
        padding: 0;
        margin-top: 1em;
        position: relative;

        @include above(map-get($breakpoints, tablet-wide)) {
            margin-top: 1.8em;
        }
        @include above(map-get($breakpoints, desktop)) {
            margin-top: 3em;
        }

        &-item {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding-bottom: 1.25em;
            position: relative;


            @include above(map-get($breakpoints, tablet-wide)) {
                width: 100%;
                padding-bottom: 1.45em;
            }
            
            @include above(map-get($breakpoints, desktop)) {
                font-size: 1.25em;
            }

            &:after {
                width: .2em;
                content: '';
                position: absolute;
                height: 100%;
                background-color: var(--white);
                border-radius: 10em;
                top: 0;
                left: 50%;
                margin-left: -10em;

                @include above(map-get($breakpoints, phone-m)) { 
                    margin-left: -10.1em;
                }
        
                @include above(map-get($breakpoints, tablet-wide)) {
                    margin-left: -11em;
                }
                @include above(map-get($breakpoints, desktop)) {
                    margin-left: -14.1em
                }
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
        &-icon {
            display: none;

            @include above(map-get($breakpoints, tablet-wide)) {
                display: block;
                width: 64px;
                height: 4em;
                transform: translateY(-0.9em);
            }

            @include above(map-get($breakpoints, tablet-wide)) {
                width: 80px;
            }

            @media screen and (min-width: map-get($breakpoints, desktop )) and (max-height: 960px) and (max-width: map-get($breakpoints, desktop-wide )) {
                width: 60px;
            }

            img {
                width: 100%;
            }
        }
        &-bullet {
            display: block;
            width: 14px;
            height: 14px;
            background-color: var(--white);
            border-radius: 1em;

            @include above(map-get($breakpoints, tablet-wide)) {
                width: 18px;
                height: 18px;
            }

            @include above(map-get($breakpoints, desktop)) {
                width: 24px;
                height: 24px;
                border-width: 4px;
            }

            @media screen and (min-width: map-get($breakpoints, desktop )) and (max-height: 960px) and (max-width: map-get($breakpoints, desktop-wide )) {
                width: 18px;
                height: 18px;
            }
            
            &-container {
                width: 40px;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                position: absolute;
                left: 0;

                @include above(map-get($breakpoints, tablet-wide)) {
                    position: relative;
                    width: 58px;
                    padding-left: 10px;
                }
                @include above(map-get($breakpoints, desktop)) {
                    width: 80px;
                }

                @media screen and (min-width: map-get($breakpoints, desktop )) and (max-height: 960px) and (max-width: map-get($breakpoints, desktop-wide )) {
                    width: 58px;
                    padding-left: 10px;
                }
            }
        }
        &-info {
            width: calc(100% - 40px);
            display: block;
            margin-left: 40px;
            
            @include above(map-get($breakpoints, tablet-wide)) {
                width: 100%;
                margin-left: 0;
                max-width: 24.5em;
            }
        }
        &-title {
            font-family: var(--title-font);
            font-weight: 700;
            font-style: italic;
            font-size: 1.25em;

            @include above(map-get($breakpoints, tablet-wide)) {
                font-size: 1.5em;
            }
        }
        &-text {
            font-size: 1.03em;

            @include above(map-get($breakpoints, tablet-wide)) {
                font-size: 0.88em;
                max-width: 25em;
            }
        }
    }
    &-content {
        &-table {
            padding: 4em 1em;

            @include below(map-get($breakpoints, tablet)) {
                padding: 0em 1em 2em;
                display: flex;
                flex-direction: column-reverse;
            }

            @include above(map-get($breakpoints, tablet-wide)) {
                display: flex;
                flex-direction: row-reverse;
                padding: 0em 0.2em 4.5em;
                justify-content: flex-end;
                align-items: center;
                max-width: 57.5em;
                margin: 0 auto;
                position: relative;
            }
            @include above(map-get($breakpoints, desktop)) {
                max-width: 76em;
            }

            &--bussines {
                @include above(map-get($breakpoints, tablet-wide)) {
                    min-height: 38em;
                    align-items: flex-start;
                }
                @include above(map-get($breakpoints, desktop)) {
                    min-height: 40em;
                }
                .tab {
                    &-content {
                        &-info {
                            @include above(map-get($breakpoints, tablet-wide)) {
                                max-width: 34.2em;
                            }
                            @include above(map-get($breakpoints, desktop)){
                                max-width: 48em;
                            }
                        }
                    }
                    &-image {
                        @include above(map-get($breakpoints, tablet-wide)) {
                            margin-top: -13.7em;
                        }
                    }
                }
            }
            &--comensal {
                @include above(map-get($breakpoints, tablet-wide)) {
                    min-height: 38em;
                    align-items: flex-start;
                }
                @include above(map-get($breakpoints, desktop)) {
                    min-height: 40em;
                }
    
                .tab {
                    &-content {
                        &-info {
                            @include above(map-get($breakpoints, tablet-wide)) {
                                max-width: 32em;
                                width: 100%;
                            }
                            @include above(1025px){
                                max-width: 34em;
                            }
                            @include above(map-get($breakpoints, desktop)){
                                max-width: 48em;
                            }
                        }
                    }
                    &-list {
                        @include above(map-get($breakpoints, desktop)){
                            margin-top: 2.3em;
                        }
                    }
                }

                // .dny-subsubtitle {
                //     &:after {
                //         height: 5.5em;
                //         top: 1.5em;
                //         bottom: inherit;
                //     }
                // }
            }
        }
        &-info {
            color: var(--white);
            padding-top: 3.5em;
            max-width: 21em;
            margin: 0 auto;

            @include above(map-get($breakpoints, tablet-wide)) {
                margin: 0;
                max-width: inherit;
                width: 100%;
            }

            @include above(map-get($breakpoints, desktop)) {
                max-width: 48em;
                padding-top: 4.8em;
                width: 100%;
            }
        }
    }
}