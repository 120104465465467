@import '../vars/vars.scss';

.tmp-registration {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    color: black;
    padding-top: 0;
}

.video {
    img {
        width: 100%;
    }
}