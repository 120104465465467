@import '../vars/vars.scss';

.dloads {
    background: url($imgPath + 'fd867f35-22cd-496d-813a-5fbd463c641b.svg') repeat;
    background-size: 80em auto;
    position: relative;
    z-index: 2;
    padding-bottom: 4em;

    @include above(map-get($breakpoints, tablet-wide)) {
        padding-bottom: 0;
    }
    
    &-img {
        margin: -5.5em auto 0;
        max-width: 20em;

        @include above(map-get($breakpoints, tablet-wide)) {
            margin: 2.3em 0 0 -3em;
            max-width: 25.5em;
        }

        @include above(map-get($breakpoints, desktop)) {
            margin: 2em 0 0 0.4em;
            max-width: 34.8em;  
        }

        img {
            width: 100%;
        }
    }
    &-tble {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;
        max-width: 23.5em;
        margin: 0 auto;

        @include above(map-get($breakpoints, tablet-wide)) {
            flex-direction: row-reverse;
            width: 100%;
            max-width: 52em;
            justify-content: space-between;
        }

        @include above(map-get($breakpoints, desktop)) {
            max-width: 76em;
        }
    }
    &-info {
        padding: 1em 1.5em;

        @include above(map-get($breakpoints, tablet-wide)) {
            max-width: 22em;
            padding-top: 4em;
        }

        @include above(map-get($breakpoints, desktop)) {
            max-width: 30em;
            padding: 5.5em 0 0 2em;
        }

        &-text {
            margin-top: 1.2em;
        }

        .dny {
            &-txt {
                font-size: 1.3em;

                @include above(map-get($breakpoints, desktop)) {
                    font-size: 1.72em;
                }
            }
        }
    }
    &-nav {
        padding-top: 2.6em;

        @include above(map-get($breakpoints, tablet-wide)) {
            padding-top: 1em;    
        }

        @include above(map-get($breakpoints, desktop)) {
            padding-top: 2em;
        }

        &-tble {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-top: 4em;
            max-width: 18em;
            width: 100%;

            @include above(map-get($breakpoints, tablet-wide)) {
                padding-top: 2.2em;
            }

            @include above(map-get($breakpoints, desktop)) {
                max-width: 20em;
            }
        }
    }
    &-applink {
        display: block;

        &--ios {
            max-width: 8em;

            @include above(map-get($breakpoints, desktop)) {
                max-width: 9em;
                width: 100%;
            }

            @include above(map-get($breakpoints, desktop)) {
                max-width: 9.2em
            }
        }

        &--android {
            max-width: 9.2em;

            @include above(map-get($breakpoints, desktop)) {
                max-width: 10.2em;
                width: 100%;
            }
            @include above(map-get($breakpoints, desktop)) {
                max-width: 10.3em
            }
        }

        img {
            width: 100%;
        }
    }
}