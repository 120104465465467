@import '../vars/vars.scss';

.faqs {
    position: relative;

    &-info {
        padding: 5em 0;

        &-text {
            padding-top: 3.4em;

            @include above(map-get($breakpoints, tablet-wide)) {
                padding-top: 4.4em;
            }

            @include above(map-get($breakpoints, desktop)) {
                padding-top: 5.4em;
            }
            
            .tabs {
                &-content {
                    padding-top: 3.4em;
                    max-width: 20em;
                    margin: auto;

                    @include above(map-get($breakpoints, tablet-wide)) {
                        max-width: 46em;
                    }

                    @include above(map-get($breakpoints, desktop)) {
                        max-width: 62em;
                        padding-top: 5.4em;
                    }
                }
                &-nav {
                    background-color: var(--grey);
                    
                    &::before {
                        background-color: var(--green100);
                    }
                    .dny-btn {
                        font-size: 1.2em;
                        color: var(--dark);
                        &--tab-active {
                            color: var(--white)
                        }
                    }
                }
            }
        }
        
    }

    .dny-subtitle {
        text-align: center;

        &-small {
            margin-bottom: 0.7em;
            
            @include above(map-get($breakpoints, tablet-wide)) {
                margin-bottom: 0;
            }
        }
    }
}