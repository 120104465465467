@import '../../vars/vars.scss';

.dny-btn {
    border: 0 none;
    position: relative;
    outline: none;
    cursor: pointer;
    font-family: var(--title-font);

    &--main {
        background-color: var(--green100);
        color: var(--white);
        padding: 0.35em 1em;
        width: 100%;
        max-width: 12em;
        border-radius: 14em;
        font-weight: 700;
        font-style: italic;
        font-size: 1.4em;
        text-align: center;
        transition: .30s linear;

        &:hover {
            @include above(map-get($breakpoints, tablet-wide)) {
                background-color: var(--green300)
            }
        }

        @include above(map-get($breakpoints, tablet-wide)) {
           font-size: 1.2em; 
           max-width: 13.5em;
        }

        @include above(map-get($breakpoints, desktop)) {
            font-size: 1.55em;
            max-width: 12em;
        }
    }
    &--outlined {
        border-color: var(--app-secnVariation-d);
        color: var(--white);
        max-width: 12em;
        width: 100%;
        display: block;
        font-weight: 700;
        background: transparent;
        text-transform: uppercase;
        font-size: 0.9em;
        border-radius: 10em;
        padding: 0.7em;
    }
    &--tab {
        border: 2px solid transparent;
        max-width: 18em;
        background: rgba(255,255,255, 0);
        width: 100%;
        display: block;
        font-weight: 400;
        font-size: 1.4em;
        text-align: center;
        border-radius: 10em;
        padding: 0.4em;
        margin: 0 auto;
        font-style: italic;
        color: var(--green100);

        @include above(map-get($breakpoints, tablet-wide)) {
            padding: 0.15em;
            font-weight: 700;
        }
    }
}
