@import '../vars/vars.scss';

.rest {
    padding-top: 4em;
    background: url($imgPath + 'fd867f35-22cd-496d-813a-5fbd463c641b.svg') repeat;
    background-size: 80em auto;

    @include above(map-get($breakpoints, tablet-wide)) {
        padding-bottom: 4em;
    }

    .dny-subtitle {
        text-align: center;
    }

    &-info {
        &-slides {
            padding-top: 2em;
            margin-bottom: 2em;

            @include above(map-get($breakpoints, tablet-wide)){
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                max-width: 90%;
                margin: 0 auto 2em;
            }

            @include above(map-get($breakpoints, desktop)) {
                padding-top: 4em;
            }
        }

        &-slide {
            &-desktop {
                padding: 0 0.6em;
                
            }
        }
    }

    &-carousel {
        width: 100%;
    }
    .swiper-wrapper {
        padding: 2em 0 ;

        @include above(map-get($breakpoints, desktop)) {
            justify-content: center;
        }
    }
    .swiper-slide {
        max-width: 18em;

        @include above(map-get($breakpoints, tablet-wide)) {
            max-width: 24em;
        }

        @include above(map-get($breakpoints, desktop)) {
            max-width: 31.5em;
        }
    }
}