.construction {
    height: calc(100vh - 9em);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 4em;

    &-content {
        text-align: center;

        .dny {
            &-subtitle {
                font-size: 1.2em;
            }
            &-txt {
                font-size: 1.25em;
            }
        }
    }
    &-nav {
        margin-top: 2em;
    }
}

.dinny-dev {
    max-width: 18em;
    margin: 0 auto 2em;
    width: 100%;
    padding-bottom: 1em;
    border-bottom: 1px solid #B3B3B3;

    .st0{fill:#E6E6E6;}
	.st1{fill:#333333;}
	.st2{fill:#B3B3B3;}
	.st3{fill:#999999;}
	.st4{fill:#8CC63F;}
	.st5{fill:#FF7BAC;}
	.st6{fill:#5ABB6E;}
	.st7{fill:#C3E2C6;}
	.st8{fill:#39AF4C;}
	.st9{fill:#FFFFFF;}
	.st10{opacity:0.44;fill:#3FA9F5;}
}