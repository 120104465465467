.hbspt-form{
    .hs-form{
        padding: 1px;

        h1 {
            font-size: 2em!important;
            display: none;
        }

        .hs-richtext.hs-font-main-element {
            h1{
                font-size: 2em!important;
            }
        }
    }
    .hs-input{
        border: 0!important;
        appearance: none!important;
        border-radius: 0!important;
        background:#fff!important;
        border-bottom: 1px solid red!important;
    }
}
