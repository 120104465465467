@import '../vars/vars.scss';

.features {
    background: url($imgPath + '48df1ad6-aa6b-4ff2-9e23-9b8482172eb7.svg');
    background-color: var(--green100);
    position: relative;
    min-height: 42em;
    padding-top: 5em;

    @include above(map-get($breakpoints, tablet-wide)) {
        min-height: 49.2em;
        padding-top: 4.8em;
    }

    @include above(map-get($breakpoints, desktop)) {
        min-height: 66.1em;
        padding-top: 6.4em;
    }

    &-layout {
        @include above(map-get($breakpoints, tablet-wide)) {
            max-width: 60em;
            margin: 0 auto;
        }

        @include above(map-get($breakpoints, desktop)) {
            max-width: 79em;
        }

        .dny {
            &-subtitle {
                @include above(map-get($breakpoints, tablet-wide)) {
                    line-height: 1.3em;
                    margin-bottom: 0.6em;
                }
                
                &-small {
                    @include above(map-get($breakpoints, tablet-wide)) {
                        font-size: .5em;
                    }
                }
            }
            &-btn {
                @include above(map-get($breakpoints, tablet-wide)) {
                    max-width: 8em;
                } 
            }
        }
    }

    &-bubbles {
        background-color: var(--white);
        color: var(--dark);
        max-width: 20.5em;
        margin: 0 auto;
        border-radius: 2em 2em 2em 2em;
        padding: 2em 1.6em;
        min-height: 17.7em;

        @include above(map-get($breakpoints, tablet-wide)) {
           max-width: 29.5em;
           margin: 0;
           border-radius: 3em 3em 3em 0em;
           padding: 2em 0 0 3.6em;
           min-height: 16.5em;
        }

        @include above(map-get($breakpoints, desktop)) {
            max-width: 38.5em;
            border-radius: 4em 4em 4em 0em;
            padding: 2em 3em 0 4.1em;
            min-height: 22em;
        }
    }

    &-list {
        color: var(--white);
        padding: 0 0 0 1em;
        max-width: 18.2em;
        margin: -1.3em auto 0;

        @include above(map-get($breakpoints, tablet-wide)) {
            margin: 2.1em 0;
            max-width: 23.2em;
        }

        @include above(map-get($breakpoints, desktop)) {
            margin: 3.1em 0;
            max-width: 25.2em;
        }

        &-item {
            font-size: 1.25em;
            font-style: italic;
            margin-bottom: 0.9em;

            @include above(map-get($breakpoints, tablet-wide)) {
                font-size: 1.05em;
                margin-bottom: 1.4em;
                line-height: 1.2em;
            }

            @include above(map-get($breakpoints, desktop)) {
                font-size: 1.4em;
            }
        }

        .txt-strong {
            font-style: italic;
        }
    }

    &-flex {
        display: flex;
        flex-direction: column-reverse;
        width: 100%;

        @include above(map-get($breakpoints, tablet-wide)) {
            flex-direction: row;
            position: relative;
            max-width: 52em;
            margin: 0 auto;
        }

        @include above(map-get($breakpoints, desktop)) {
            max-width: 68em;
        }
    }

    &-parallax {
        max-width: 22em;
        width: 100%;
        margin: -3.3em auto 0;

        @include above(map-get($breakpoints, tablet-wide)) {
            max-width: 32.4em;
            position: absolute;
            right: 50%;
            top: 50%;
            margin-right: -31.1em;
            margin-top: -26.9em;
        }

        @include above(map-get($breakpoints, desktop)) {
            max-width: 43.6em;
            margin-right: -41.4em;
            margin-top: -36.8em;
        }

        img {
            width: 100%;
        }
    }
}