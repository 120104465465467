@import '../vars/vars.scss';

.dny {
    &-namnam {
        position: absolute;
        top: -5em;
        right: 1.7em;
        width: 35em;
        height: 35em;

        @include above(map-get($breakpoints, desktop)) {
            top: -6em;
            right: -2.4em;
            font-size: 1.35em;
        }

        &--purple {
            position: absolute;
            width: 9.2em;
            right: 50%;
            top: 50%;
            margin-top: -8.3em;
            margin-right: -17.8em;
        }
        &--red {
            position: absolute;
            width: 9.2em;
            left: 50%;
            top: 50%;
            margin-top: -7.8em;
            margin-left: -15.9em;
        }
        &--phone {
            position: absolute;
            width: 25.4em;
            top: 50%;
            left: 50%;
            margin-top: -16.6em;
            margin-left: -16.2em;
        }
    }
}