@import '../vars/vars.scss';

[data-theme="root"] {
  --title-font: 'Prometo', sans-serif;
  --text-font: 'Roboto', 'sans-serif';


  font-family: var(--text-font);
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include below(335px) {
    font-size: 14px;
  }

  @media screen and (min-width: map-get($breakpoints, desktop )) and (max-height: 960px) and (max-width: map-get($breakpoints, desktop-wide )) {
    font-size: 12px;
  }

}

.transitionWrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
