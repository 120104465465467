@import '../../vars/vars.scss';

.dny {
    &-footer {
        background-color: var(--grey);
        padding: 2.8em 1.5em;
        position: relative;
        z-index: map-get($layers, fourthFloor );

        @include above(map-get($breakpoints, tablet-wide)) {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 auto;
            padding: 2em 1.5em;
        }

        @include above(map-get($breakpoints, desktop)) {
            padding: 2.5em 1.5em;
        }

        &-table {
            padding-bottom: 5em;

            @include above(map-get($breakpoints, tablet-wide)) {
                width: 100%;
                display: flex;
                flex-direction: row;
                padding-bottom: 3em;
                max-width: 75em;
                margin: 0 auto;
            }
        }

        &-info {
            margin-top: 5em;

            @include above(map-get($breakpoints, tablet-wide)) {
                margin-top: 0;
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                padding-top: 1em;
            }

            &-desktp {
                @include above(map-get($breakpoints, tablet-wide)) {
                    display: flex;
                    width: 100%;
                    flex-direction: column;
                    align-items: flex-end;
                }
            }
        }

        &-lists {
            padding: 0;
            list-style-type: none;
        }

        &-list {
            text-align: center;

            @include above(map-get($breakpoints, tablet-wide)) {
                text-align: left;
                min-width: 10.2em;
                margin-right: 5.4em;
            }

            @include above(map-get($breakpoints, desktop)) {
                margin-right: 8em;
            }

            &:nth-child(2) {
                margin-top: 3em;

                @include above(map-get($breakpoints, tablet-wide)) {
                    margin: 0;
                }

                @include above(map-get($breakpoints, desktop)) {
                    margin: 0 2.8em 0 0
                }
            }

            &-title {
                font-weight: 700;
                color: var(--dark);
                font-size: 1.45em;
                padding-bottom: 0.8em;

                @include above(map-get($breakpoints, tablet-wide)) {
                    font-size: 1.14em;
                }

                @include above(map-get($breakpoints, desktop)) {
                    font-size: 1.45em;
                }
            }

            &-link {
                font-weight: 400;
                color: var(--markgrey);
                text-decoration: none;
            }

            &-item {
                font-size: 1.25em;
                padding-bottom: 0.4em;

                @include above(map-get($breakpoints, tablet-wide)) {
                    font-size: 0.85em;
                    padding-bottom: 0.4em;
                }
                @include above(map-get($breakpoints, desktop)) {
                    font-size: 1.25em;
                }
            }
        }

        &-socials {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 2.5em;
            max-width: 10em;
            width: 100%;
            margin: 2.5em auto 0.4em;

            @include above(map-get($breakpoints, tablet-wide)) {
                margin: 0;
                max-width: 8em;
                margin-right: 3.8em;
                min-width: 8em;
                padding-top: 2em;
            }

            @include above(map-get($breakpoints, desktop)) {
                max-width: 10em;
                margin-right: 4.8em;
                min-width: 10em;
            }

            &-link {
                font-size: 2em;
                color: var(--black);

                @include above(map-get($breakpoints, tablet-wide)) {
                    font-size: 1.6em;
                }
                @include above(map-get($breakpoints, desktop)) {
                    font-size: 2em;
                }
            } 
        }

        .dloads-nav-tble {
            margin: 0 auto;
            max-width: 20em;
            padding-top: 2.8em;

            @include above(map-get($breakpoints, tablet-wide)) {
                padding: 0;
                margin: 0 9em 0;
                font-size: 0.75em;
            }
            
            @include above(map-get($breakpoints, desktop)) {
                padding: 0;
                margin: 0px 8.4em 0;
                font-size: 1em;
            }

            .dloads-applink {
                &--android {
                    max-width: 9.7em;
                    width: 100%;
                }
                &--ios {
                    max-width: 8.9em;
                    width: 100%;
                }

            }
        }
        &-disclaimer {
            padding-top: 3em;
            text-align: center;

            @include above(map-get($breakpoints, tablet-wide)) {
                width: 100%;
                padding-top: 1em;
            }
            @include above(map-get($breakpoints, desktop)) {
                padding-top: 2em;
            }

            &-table {
                @include above(map-get($breakpoints, tablet-wide)) {
                    font-size: 0.85em;
                    justify-content: space-between;
                    align-items: flex-end;
                    display: flex;
                    flex-direction: row;
                    max-width: 64em;
                    margin: 0 auto;
                    width: 100%;
                }

                @include above(map-get($breakpoints, desktop)) {
                    font-size: 1.05em;
                    max-width: 72em;
                }
            }

            &--left {
                text-align: center;

                @include above(map-get($breakpoints, tablet-wide)) {
                    text-align: left;
                }
            }

            &--simples {
                text-align: center;
                padding-top: 2em;
                
                @include above(map-get($breakpoints, tablet-wide)) {
                    text-align: right;
                    padding-top: 0;
                }
            }

            .dny-txt {
                &--strong {
                    font-weight: 700;
                    font-size: 1.25em;
                }
                &--softgray {
                    color: var(--markgrey);
                }
            }
        }
        &-separator {
            width: 100%;
            max-width: 75em;
            margin: 0 auto;
            
            hr{
                width: 100%;
                height: 1px;
                background-color: var(--markgrey);
            }
        }
    }
}