// Index layers
$layers: (
  "base"          : 0,
  "firstFloor"    : 1,
  "secondFloor"   : 2,
  "thirdFloor"    : 3,
  "fourthFloor"   : 4,
  "topFloor"      : 5,
  "outaSpace"     : 99
);
